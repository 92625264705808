body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dfe1e3;
  margin-top: 12px;
  margin-bottom: 14px;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #afadb9;
  border-radius: 30px;
  height: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333333;
}

button[dir='ltr'] .MuiButton-startIcon {
  margin-left: -4px;
  margin-right: 8px;
}
